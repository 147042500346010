import React, { useContext, createContext, useState, useEffect } from 'react';
import useFontFaceObserver from 'use-font-face-observer';

const CriticalRenderContext = createContext(undefined);

export const CriticalRenderProvider = ({ children, value }) => {
  const [assetsLoaded, setAssetsLoaded] = useState(false);
  const isPlaidSansLoaded = useFontFaceObserver([{ family: `Plaid Sans` }]);

  useEffect(() => {
    const images = Array.from(
      document.querySelectorAll(value.assetQuerySelector),
    );

    const checkIfAllLoaded = () => {
      const allLoaded = images.every((img) => {
        return img.complete && img.naturalHeight !== 0;
      });
      setAssetsLoaded(allLoaded);
    };

    checkIfAllLoaded();

    images.forEach((img) => {
      img.addEventListener('load', checkIfAllLoaded);
      img.addEventListener('error', checkIfAllLoaded);
    });

    return () => {
      images.forEach((img) => {
        img.removeEventListener('load', checkIfAllLoaded);
        img.removeEventListener('error', checkIfAllLoaded);
      });
    };
  }, [value.assetQuerySelector]);

  const haveAllCriticalAssetsLoaded = assetsLoaded && isPlaidSansLoaded;

  return (
    <CriticalRenderContext.Provider
      value={{
        haveAllCriticalAssetsLoaded,
        assetsLoaded,
        isPlaidSansLoaded,
      }}
    >
      {children}
    </CriticalRenderContext.Provider>
  );
};

export const useCriticalRenderContext = () => {
  return useContext(CriticalRenderContext);
};
