import styled from 'threads5/styles/styled';
import React, { useRef } from 'react';
import useWorkerGuilloche from 'src/hooks/useWorkerGuilloche';
import { useLazyGuilloche } from 'src/hooks/useLazyGuillcohe';
import { Box, Column, Row, Stack, Container } from 'src/components-v2/Layout';
import { Image } from 'src/components-v2/Media';
import {
  Button,
  IButtonProps,
} from 'src/components-v3/Homepage/Buttons/Button';

const SolutionCards = () => {
  const canvas1 = useRef<HTMLCanvasElement>(null);
  const { guilloche: dune, isReady: isDuneReady } = useLazyGuilloche({
    pattern: 'dune',
    canvasRef: canvas1,
    rootMargin: '-30% 0px 0px 0px',
  });
  useWorkerGuilloche({
    canvasRef: canvas1,
    svgPaths: dune?.paths,
    svgWidth: dune?.width,
    svgHeight: dune?.height,
    intensity: 0.5,
    speed: 0.18,
    horizontalWave: true,
    verticalWave: true,
    zAxisWave: true,
    id: 'canvas1',
    scale: { xs: 1.5, xxl: 2 },
    y: { xs: -20, sm: -5 },
    rootMargin: '-30% 0px 0px 0px',
    disableAnimation: !isDuneReady,
  });

  const canvas2 = useRef<HTMLCanvasElement>(null);
  const { guilloche: ribbon2, isReady: isRibbon2Ready } = useLazyGuilloche({
    pattern: 'donut',
    canvasRef: canvas2,
    rootMargin: '-30% 0px 0px 0px',
  });
  useWorkerGuilloche({
    canvasRef: canvas2,
    svgPaths: ribbon2?.paths,
    svgWidth: ribbon2?.width,
    svgHeight: ribbon2?.height,
    intensity: 0.5,
    speed: 0.18,
    horizontalWave: true,
    verticalWave: false,
    zAxisWave: false,
    intersectionThreshold: 0,
    id: 'canvas2',
    scale: { xs: 1, sm: 1.2, xxl: 2 },
    y: { xs: 0, sm: -15, md: 5, lg: -4, xxl: -40 },
    rootMargin: '-30% 0px 0px 0px',
    disableAnimation: !isRibbon2Ready,
  });

  const canvas3 = useRef<HTMLCanvasElement>(null);
  const { guilloche: ribbon, isReady: isRibbonReady } = useLazyGuilloche({
    pattern: 'ribbon',
    canvasRef: canvas3,
    rootMargin: '-30% 0px 0px 0px',
  });
  useWorkerGuilloche({
    canvasRef: canvas3,
    svgPaths: ribbon?.paths,
    svgWidth: ribbon?.width,
    svgHeight: ribbon?.height,
    intensity: 0.5,
    speed: 0.18,
    horizontalWave: true,
    verticalWave: false,
    zAxisWave: false,
    id: 'canvas3',
    scale: { xs: 1, sm: 2.5, md: 2, lg: 2.5, xxl: 4 },
    y: { xs: -16, sm: -16 },
    rootMargin: '-30% 0px 0px 0px',
    disableAnimation: !isRibbonReady,
  });

  const canvas4 = useRef<HTMLCanvasElement>(null);
  const { isReady: isDune2Ready } = useLazyGuilloche({
    pattern: 'dune',
    canvasRef: canvas4,
    rootMargin: '-30% 0px 0px 0px',
  });
  useWorkerGuilloche({
    canvasRef: canvas4,
    svgPaths: dune?.paths,
    svgWidth: dune?.width,
    svgHeight: dune?.height,
    intensity: 0.5,
    speed: 0.18,
    horizontalWave: true,
    verticalWave: true,
    zAxisWave: true,
    intersectionThreshold: 0,
    id: 'canvas4',
    scale: { xs: 1.5, xxl: 3 },
    y: { xs: -5, sm: -8, md: -10, xl: -10, xxl: -8 },
    rootMargin: '-30% 0px 0px 0px',
    disableAnimation: !isDune2Ready,
  });

  const canvas5 = useRef<HTMLCanvasElement>(null);
  const { guilloche: dune2, isReady: isPuddleReady } = useLazyGuilloche({
    pattern: 'puddle',
    canvasRef: canvas5,
    rootMargin: '-30% 0px 0px 0px',
  });
  useWorkerGuilloche({
    canvasRef: canvas5,
    svgPaths: dune2?.paths,
    svgWidth: dune2?.width,
    svgHeight: dune2?.height,
    intensity: 0.375,
    speed: 0.18,
    horizontalWave: true,
    verticalWave: true,
    zAxisWave: true,
    id: 'canvas5',
    scale: { xs: 1, md: 1, xl: 2, xxl: 3 },
    y: { xs: 1, sm: 17, md: 15, xl: 25, xxl: 15 },
    rootMargin: '-30% 0px 0px 0px',
    disableAnimation: !isPuddleReady,
  });

  const cards = [
    {
      guilloche: {
        pattern: 'dune',
        sx: {
          mt: { xs: '-180px', sm: '-360px' },
        },
      },
      column: {
        xsOffset: 2,
        xs: 20,
        smOffset: 0,
        sm: 24,
        sx: {
          height: '100%',
        },
      },
      image: {
        src: 'https://images.ctfassets.net/ss5kfr270og3/1e685hi5vTBe6bDJonoRZF/81127935b25b23b1b68a12552f1f2600/Crop__3_.png?fm=webp&q=70&w=914',
        sources: {
          xs: 'https://images.ctfassets.net/ss5kfr270og3/1e685hi5vTBe6bDJonoRZF/81127935b25b23b1b68a12552f1f2600/Crop__3_.png?fm=webp&q=70&w=870',
        },
        width: 457,
        height: 557,
        alt: 'instant onboarding with your plaid saved account',
        sx: {
          mt: '-8px',
        },
      },
      heading: {
        text: `The internet's fastest financial onboarding`,
      },
      subheading: {
        text: 'Increase conversion, fight fraud, and onboard customers to your app or service in seconds.',
      },
      button: {
        text: 'See onboarding in action',
        href: '/layer/',
        variant: 'green' as IButtonProps['variant'],
      },
      sx: {
        background: 'linear-gradient(48deg, #088181 0%, #156843 100%)',
        boxShadow: '0px -30px 50px -10px rgba(124, 240, 66, 0.30)',
      },
    },
    {
      guilloche: {
        pattern: 'donut',
        sx: {
          mt: { xs: '-430px', sm: '-300px' },
        },
      },
      column: {
        xsOffset: 2,
        xs: 20,
        smOffset: 0,
        sm: 24,
        sx: {
          height: '100%',
        },
      },
      image: {
        src: 'https://images.ctfassets.net/ss5kfr270og3/6Ax7Fx3NQVIgSLOyEtqOs6/73b1bdb6b20ecd916f6f24c8865d1f4f/billPayProductShot.png?fm=webp&q=70&w=916&h=1000&fit=crop&f=bottom',
        sources: {
          xs: 'https://images.ctfassets.net/ss5kfr270og3/6Ax7Fx3NQVIgSLOyEtqOs6/73b1bdb6b20ecd916f6f24c8865d1f4f/billPayProductShot.png?fm=webp&q=70&w=870&h=1000&fit=crop&f=bottom',
        },
        width: 457,
        height: 557,
        alt: 'fast and secure payments for subscriptions from Plaid',
        sx: {
          mt: '-8px',
        },
      },
      heading: {
        text: `Fast and secure payments`,
      },
      subheading: {
        text: 'Increase revenue with seamless bank payments designed to deliver higher conversion rates.',
      },
      button: {
        text: 'Explore payment solutions',
        href: '/solutions/payments/',
        variant: 'blue' as IButtonProps['variant'],
      },
      sx: {
        background: 'linear-gradient(48deg, #088181 0%, #006477 100%)',
        boxShadow: '0px -30px 50px -10px rgba(182, 251, 233, 0.50)',
      },
    },
    {
      guilloche: {
        pattern: 'ribbon',
        sx: {
          mt: { xs: '-50px', sm: '-160px' },
        },
      },
      column: {
        xsOffset: 0,
        xs: 24,
        smOffset: 0,
        sm: 24,
        sx: {
          height: '100%',
        },
      },
      stack: {
        sx: {
          mt: { xs: '57px', xl: '125px' },
        },
      },
      image: {
        src: 'https://images.ctfassets.net/ss5kfr270og3/5dRT581AMyE4Mm5dCPkWs2/a0cd6faf4f42157d8d9f8b577d3d916a/fraudProductShot.png?fm=webp&q=70&w=2282&h=765&fit=crop&f=bottom',
        sources: {
          xs: 'https://images.ctfassets.net/ss5kfr270og3/1Yfy4i6CG7T0rTUUcko0Be/b40ac6c09320f7fae1be809d2ec0284b/fraudProductShotMobile.png?fm=webp&q=70&w=870&h=680&fit=crop&f=bottom',
        },
        width: 1113,
        height: 414,
        alt: 'fraud signals from Plaid',
        sx: {
          mt: { xs: '48px', xl: 0 },
        },
      },
      heading: {
        text: `Network-powered fraud signals`,
      },
      subheading: {
        text: 'Stop identity fraud, account takeovers, and risky payments with ML-powered fraud signals.',
      },
      button: {
        text: 'See how we protect you',
        href: '/solutions/fraud-compliance/',
        variant: 'purple' as IButtonProps['variant'],
      },
      sx: {
        background: 'linear-gradient(48deg, #00819A 0%, #633ECB 100%)',
        boxShadow: '0px -30px 50px -12px rgba(216, 194, 251, 0.80)',
      },
    },
    {
      guilloche: {
        pattern: 'dune2',
        sx: {
          mt: { xs: '-190px', sm: '-160px' },
        },
      },
      column: {
        xsOffset: 0,
        xs: 24,
        smOffset: 0,
        sm: 24,
        sx: {
          height: '100%',
        },
      },
      stack: {
        sx: {
          mt: { xs: '57px', xl: '125px' },
        },
      },
      image: {
        src: 'https://images.ctfassets.net/ss5kfr270og3/4nVdQyCOkdbfhxEo8Vl5Q5/894732a052112498f61c76f2ce2f718a/cashFlowProductShot.png?fm=webp&q=70&w=2268&h=765&fit=crop&f=bottom',
        sources: {
          xs: 'https://images.ctfassets.net/ss5kfr270og3/6PsZfVfKyPMtqEh3EByJc5/2bc5a069662d96094a14125679a7c6a6/cashFlowProductShotMobile.png?fm=webp&q=50&w=870&h=630&fit=crop&f=bottom',
        },
        width: 1133,
        height: 414,
        alt: 'graph of cash flow data by Plaid',
        sx: {
          mt: { xs: '66px', xl: 0 },
        },
      },
      heading: {
        text: `Cash flow data for smarter underwriting`,
      },
      subheading: {
        text: `Say “yes” to more qualified borrowers and reduce risk with real-time lending insights.`,
      },
      button: {
        text: 'Tap into cash flow insights',
        href: '/solutions/credit/',
        variant: 'pink' as IButtonProps['variant'],
      },
      sx: {
        background: 'linear-gradient(48deg, #BB33D7 0%, #633ECB 100%)',
        boxShadow: '0px -30px 50px -12px rgba(216, 194, 251, 0.60)',
      },
    },
    {
      guilloche: {
        pattern: 'puddle',
      },
      column: {
        xsOffset: 2,
        xs: 20,
        smOffset: 0,
        sm: 24,
        sx: {
          height: '100%',
        },
      },
      stack: {},
      image: {
        src: 'https://images.ctfassets.net/ss5kfr270og3/NfmvfB7TrXO8gGKcXLDx5/43616a1ae7a75690bfb198655f811c22/openFinanceProductShot.png?fm=webp&q=50&w=1836&h=1000&fit=crop&f=bottom',
        sources: {
          xs: 'https://images.ctfassets.net/ss5kfr270og3/1IbazyPL8DexOIqyi2jp0I/76d2d8316844677df038b7e342299402/openFinanceProductShotMobile.png?fm=webp&q=50&w=870&h=820&fit=crop&f=bottom',
        },
        width: 918,
        height: 541,
        alt: 'open finance solutions from Plaid',
        sx: {
          mt: '-8px',
        },
      },
      heading: {
        text: `Open finance solutions for everyone`,
      },
      subheading: {
        text: `Meet open banking requirements and make it easy for customers to connect to the financial apps they need.`,
      },
      button: {
        text: 'Simplify data connectivity',
        href: '/solutions/open-finance/',
        variant: 'pink' as IButtonProps['variant'],
      },
      sx: {
        background: 'linear-gradient(48deg, #CE4553 0%, #8F29A3 100%)',
        boxShadow: '0px -30px 50px -12px rgba(242, 176, 255, 0.50)',
        xs: {},
      },
    },
  ];

  const guillochePatterns = {
    dune: canvas1,
    donut: canvas2,
    ribbon: canvas3,
    dune2: canvas4,
    puddle: canvas5,
  };

  return (
    <Box
      sx={{
        background:
          'linear-gradient(176deg, #E5FFDD 3.31%, #D0FBFC 28.98%, #F5F5FF 78.61%)',
        pt: { xs: '27px', sm: '64px' },
      }}
    >
      {cards.map((card, index) => {
        return (
          <>
            <Box
              key={card.guilloche.pattern + index}
              component={'section'}
              sx={{
                borderRadius: '40px',
                height: 'auto',
                m: { xs: '0 16px 32px', sm: '0 64px 64px' },
                width: { xs: 'calc(100% - 32px)', sm: 'calc(100% - 128px)' },
                overflow: 'hidden',
                position: 'relative',
                '.solution-card-stack': {
                  marginBottom: { xs: '57px', xl: '125px' },
                },
                ...card.sx,
              }}
            >
              <Canvas
                ref={guillochePatterns[card.guilloche.pattern]}
                {...card.guilloche}
              />
              <Container sx={{ height: '100%' }}>
                <Row sx={{ height: '100%' }}>
                  <Column {...card.column}>
                    <Stack
                      className='solution-card-stack'
                      direction='column'
                      alignContent='center'
                      justifyContent='center'
                      alignItems='center'
                      sx={{
                        position: 'relative',
                        zIndex: 1,
                        ...card?.stack?.sx,
                      }}
                    >
                      <ProductShot {...card.image} isLazy={true} />
                      <Heading2>{card.heading.text}</Heading2>
                      <Subheading>{card.subheading.text}</Subheading>
                      <Button
                        href={card.button.href}
                        variant={card.button.variant}
                      >
                        {card.button.text}
                      </Button>
                    </Stack>
                  </Column>
                </Row>
              </Container>
            </Box>
          </>
        );
      })}
    </Box>
  );
};

export default SolutionCards;

const Heading2 = styled('h2')(({ theme }) => {
  return {
    color: '#FFF',
    fontSize: '5rem',
    fontWeight: 500,
    lineHeight: '110%',
    letterSpacing: '-2px',
    marginBottom: '3.2rem',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      marginBottom: '1.6rem',
      fontSize: '2.4rem',
      letterSpacing: '-0.96px',
    },
    fontFamily: theme.typography.plaidSans,
  };
});

const Subheading = styled('p')(({ theme }) => {
  return {
    color: '#FFF',
    fontSize: '2.4rem',
    lineHeight: '132%',
    marginBottom: '4.8rem',
    width: '540px',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: '2.2rem',
      marginBottom: '4rem',
      width: '465px',
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '1.8rem',
      lineHeight: '130%',
      width: 'auto',
      maxWidth: '295px',
    },
  };
});

const Canvas = styled('canvas')({
  position: 'absolute',
  background: 'transparent',
  top: '50%',
  left: '50%',
  transform: `translate(-50%, -50%)`,
  width: '100%',
  height: '100%',
  zIndex: 0,
});

const ProductShot = styled(Image)(({ theme }) => {
  return {
    marginBottom: '40px',
  };
});
