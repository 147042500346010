import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { easings, to, useSprings, useSpringValue } from '@react-spring/web';
import Icon from 'threads5/Icon';
import throttle from 'lodash/throttle';
import { useSwipeable } from 'react-swipeable';

import {
  Column,
  Container,
  Row,
  Section,
  Stack,
  Box,
} from 'src/components-v2/Layout';
import useHasMouse from 'src/hooks/useHasMouse';
import useGradientText from 'src/hooks/useGradientText';
import IconButton from '../Buttons/IconButton';
import {
  Slider,
  CarouselItem,
  Description,
  Title,
  Image,
  LinkWrapper,
  Canvas,
} from './Carousel.styled';
import { baseItems } from './carousel-data';
import Link from 'src/components-v2/Inputs/Link/Link';
import useWorkerGuilloche from 'src/hooks/useWorkerGuilloche';
import Btn from 'src/components-v2/Inputs/Button/Btn';
import { useLazyGuilloche } from 'src/hooks/useLazyGuillcohe';

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(
    typeof window !== 'undefined' && window.innerWidth <= 639,
  );

  useEffect(() => {
    const handleResize = throttle(() => {
      setIsMobile(window.innerWidth <= 639);
    }, 150);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      handleResize.cancel();
    };
  }, []);

  return isMobile;
};

const imgQuery = '?fm=webp&q=70';

const getMultiplierToEighteen = (n) => {
  return Math.ceil(18 / n);
};

const MOBILE_WIDTH = 250;
const MOBILE_GAP = 12;
const DESKTOP_WIDTH = 420;
const DESKTOP_GAP = 40;

const generateInitialItems = (isMobile) => {
  const width = isMobile ? MOBILE_WIDTH : DESKTOP_WIDTH;
  const gap = isMobile ? MOBILE_GAP : DESKTOP_GAP;

  const multiplier = getMultiplierToEighteen(baseItems.length);
  let count = 0;
  const bigArray = new Array(multiplier).fill(baseItems).flatMap((arr) => {
    return arr.map((entity) => {
      const leftVal = count * (width + gap);
      count++;
      return {
        ...entity,
        id: uuidv4(),
        left: leftVal,
      };
    });
  });
  return bigArray;
};

const getXOffset = (
  clickedIndex,
  isLeftOfClicked,
  isRightOfClicked,
  hasMouse,
) => {
  // return early to avoid adjacent element shifting
  return 0;
  if (!hasMouse) {
    return 0;
  }
  if (clickedIndex == null) {
    return 0;
  }
  if (isLeftOfClicked) {
    return -25;
  }
  if (isRightOfClicked) {
    return 25;
  }
  return 0;
};

const CarouselInner = ({ canvas }) => {
  const { elementRef, handleMouseMove, parentRef } = useGradientText<
    HTMLHeadingElement,
    HTMLDivElement
  >({
    gradientColors: '#42F0CE 2.7%, #10B1F3 102.04%',
  });
  const hasMouse = useHasMouse();
  const isMobile = useIsMobile();
  const width = isMobile ? MOBILE_WIDTH : DESKTOP_WIDTH;
  const gap = isMobile ? MOBILE_GAP : DESKTOP_GAP;

  const sliderRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    const throttledUpdateWidth = throttle(() => {
      if (sliderRef.current) {
        setContainerWidth(sliderRef.current.offsetWidth);
      }
    }, 150);

    throttledUpdateWidth();

    window.addEventListener('resize', throttledUpdateWidth);

    return () => {
      window.removeEventListener('resize', throttledUpdateWidth);
      throttledUpdateWidth.cancel();
    };
  }, []);

  const [activeId, setActiveId] = useState(null);
  const [state, setState] = useState({ items: [] });
  const sliderXPos = useSpringValue(0, {
    config: { duration: 300, easing: easings.easeOutCirc },
  });
  const [currentX, setCurrentX] = useState(0);
  const [springs, api] = useSprings(state.items.length, (index) => {
    return {
      scale: 1,
      x: 0,
      opacity: 1,
      config: { duration: 300, easing: easings.easeOutCirc },
    };
  });
  const [visibleItems, setVisibleItems] = useState(new Set());

  // set up initial state
  useEffect(() => {
    const newItems = generateInitialItems(isMobile);
    setState({ items: newItems });

    const initialPosition = width * baseItems.length + gap * baseItems.length;
    sliderXPos.set(-initialPosition);
    setCurrentX(-initialPosition);

    setActiveId(null);
  }, [isMobile, width, gap, sliderXPos]);

  const handlePrev = (shiftBy?: number) => {
    if (isAnimating) return;

    setIsAnimating(true);

    const current = sliderXPos.get();
    const elementsToShift =
      shiftBy || (visibleItems.size > 0 ? visibleItems.size : 1);
    const newX = current + (width + gap) * elementsToShift;
    sliderXPos.start({
      to: newX,
      onRest: () => {
        setState((prev) => {
          const lastElements = prev.items
            .splice(prev.items.length - elementsToShift, elementsToShift)
            .reverse()
            .map((item, idx) => {
              return {
                ...item,
                left: prev.items[0].left + (idx + 1) * -(width + gap),
              };
            })
            .reverse();
          return { ...prev, items: [...lastElements, ...prev.items] };
        });
        setCurrentX(newX);
        setIsAnimating(false);
      },
    });
  };

  const handleNext = (shiftBy?: number) => {
    if (isAnimating) return;
    setIsAnimating(true);

    const current = sliderXPos.get();
    const elementsToShift =
      shiftBy || (visibleItems.size > 0 ? visibleItems.size : 1);
    const newX = current - (width + gap) * elementsToShift;
    sliderXPos.start({
      to: newX,
      onRest: () => {
        setState((prev) => {
          const offscreenElements = prev.items
            .splice(0, elementsToShift)
            .map((item, idx) => {
              return {
                ...item,
                left:
                  prev.items[prev.items.length - 1].left +
                  (idx + 1) * (width + gap),
              };
            });
          return { ...prev, items: [...prev.items, ...offscreenElements] };
        });
        setCurrentX(newX);
        setIsAnimating(false);
      },
    });
  };

  const handleScale = (itemId, isInFrame) => {
    // this only fires on mouse leave/blur
    const isActive = itemId === activeId;
    if (!itemId) {
      setActiveId(null);
      api.start((i) => {
        return {
          scale: 1,
          x: 0,
          opacity: isActive ? 1 : 0.5,
        };
      });
      return;
    }

    // otherwise, triggers a re-render and scaling is handled in the useEffect
    const clickedIndex = state.items.findIndex((it) => {
      return it.id === itemId;
    });
    if (clickedIndex === -1) return;
    if (isInFrame) {
      setActiveId(itemId);
    }
  };

  // handle visible items
  useEffect(() => {
    const visibleStart = 0;
    const visibleEnd = containerWidth;

    const newVisible = new Set();
    state.items.forEach((item, index) => {
      const itemLeft = item.left + currentX;
      const itemRight = itemLeft + width;
      if (itemLeft >= visibleStart && itemRight <= visibleEnd) {
        newVisible.add(index);
      }
    });
    setVisibleItems(newVisible);

    if (newVisible.size > 0) {
      const visibleIndicesArray = Array.from(newVisible);
      const firstVisibleIndex = Math.min(
        ...visibleIndicesArray.map((i) => {
          return Number(i);
        }),
      );
      const lastVisibleIndex = Math.max(
        ...visibleIndicesArray.map((i) => {
          return Number(i);
        }),
      );
      const numberOfVisibleItems = lastVisibleIndex - firstVisibleIndex + 1;

      const totalGroupWidth =
        numberOfVisibleItems * width + (numberOfVisibleItems - 1) * gap;

      const firstVisibleItem = state.items[firstVisibleIndex];
      const desiredTranslateX =
        containerWidth / 2 - totalGroupWidth / 2 - firstVisibleItem.left;

      sliderXPos.start({
        to: desiredTranslateX,
        config: { duration: 300, easing: easings.easeOutCirc },
      });
      setCurrentX(desiredTranslateX);
    }
  }, [currentX, containerWidth, state.items, width, gap, sliderXPos]);

  // handle clicking outside the visible frame
  const handleClick = (itemId, isInFrame) => {
    if (isInFrame) return;

    const clickedIndex = state.items.findIndex((it) => {
      return it.id === itemId;
    });
    if (clickedIndex === -1) return;
    const visibleIndices = Array.from(visibleItems);
    if (visibleIndices.length === 0) return;
    const [min, max] = [
      Math.min(
        ...visibleIndices.map((i) => {
          return Number(i);
        }),
      ),
      Math.max(
        ...visibleIndices.map((i) => {
          return Number(i);
        }),
      ),
    ];
    const leftOfFrame = clickedIndex < min;
    const rightOfFrame = clickedIndex > max;

    if (leftOfFrame) {
      handlePrev(1);
    } else if (rightOfFrame) {
      handleNext(1);
    }
  };

  // handle scaling and element shifting left/right
  useEffect(() => {
    api.start((i) => {
      const item = state.items[i];
      const activeIndex = state.items.findIndex((it) => {
        return it.id === activeId;
      });
      const isActive = item.id === activeId;
      if (!activeId) {
        return {
          scale: 1,
          x: 0,
          opacity: hasMouse && isActive ? 0.5 : 1,
        };
      }

      const isLeftOfClicked = i < activeIndex;
      const isRightOfClicked = i > activeIndex;
      return {
        scale: isActive && hasMouse ? 1.12 : 1,
        x: getXOffset(activeIndex, isLeftOfClicked, isRightOfClicked, hasMouse), // do shifting of adjacent elements
        opacity: isActive ? 1 : 0.5,
      };
    });
  }, [activeId, state.items, api, hasMouse]);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      handleNext();
    },
    onSwipedRight: () => {
      handlePrev();
    },
    delta: 10, // min distance(px) before a swipe starts.
    preventScrollOnSwipe: true, // prevents scroll during swipe
    trackTouch: true, // track touch input
    trackMouse: true, // track mouse input
    swipeDuration: 1000, // allowable duration of a swipe (ms)
    touchEventOptions: { passive: true }, // options for touch listeners
  });

  return (
    <Section
      className='hide-for-aeo'
      ref={parentRef}
      onMouseMove={handleMouseMove}
      sx={{ overflow: 'hidden', pt: { xs: '67px', md: '164px' } }}
    >
      <Container>
        <Row>
          <Column xsOffset={1} xs={22}>
            <Stack
              direction={{ xs: 'column', lg: 'row' }}
              justifyContent='space-between'
              gap={2}
            >
              <Title
                ref={elementRef}
                sx={{
                  background:
                    'radial-gradient(circle at top left, #40EED0 2.7%, #2EBBF5 102.04%)',
                  backgroundClip: 'text',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  mb: { xs: '0px', lg: '48px' },
                }}
              >
                See what's possible with Plaid
              </Title>
              <Stack
                sx={{
                  ml: { xs: 'unset', lg: 'auto' },
                  mb: { xs: '24px', md: '40px', lg: 'unset' },
                }}
                direction='row'
                gap={2}
              >
                <IconButton
                  icon={(iconProps) => {
                    return <Icon name='ArrowLeft' {...iconProps} size='20' />;
                  }}
                  onClick={() => {
                    handlePrev();
                  }}
                  appearance='outline'
                  size='56'
                >
                  Previous items
                </IconButton>
                <IconButton
                  icon={(iconProps) => {
                    return <Icon name='ArrowRight' {...iconProps} size='20' />;
                  }}
                  onClick={() => {
                    handleNext();
                  }}
                  appearance='outline'
                  size='56'
                >
                  Next items
                </IconButton>
              </Stack>
            </Stack>
          </Column>
        </Row>
      </Container>
      <div {...handlers}>
        <Slider
          ref={sliderRef}
          sx={{
            height: { xs: '250px', md: '500px' },
          }}
          style={{
            transform: sliderXPos.to((val) => {
              return `translateX(${val}px)`;
            }),
          }}
        >
          {springs.map(({ scale, x, opacity }, i) => {
            const item = state.items[i];
            const isInFrame = visibleItems.has(i);
            const isActive = activeId === item.id;
            return (
              <CarouselItem
                key={item.id}
                id={item.id}
                tabIndex={-1}
                sx={{
                  width: width,
                  left: item.left,
                }}
                style={{
                  transform: to([x, scale], (xVal, sVal) => {
                    return `translateX(${xVal}px) scale(${sVal})`;
                  }),
                  opacity: to([opacity], (oVal) => {
                    return oVal;
                  }),
                }}
              >
                <Box
                  role='button'
                  tabIndex={-1}
                  aria-pressed={activeId === item.id}
                  onClick={() => {
                    return handleClick(item.id, isInFrame);
                  }}
                  onMouseEnter={() => {
                    if (hasMouse) {
                      return handleScale(item.id, isInFrame);
                    }
                  }}
                  onMouseLeave={() => {
                    if (hasMouse) {
                      return handleScale(null, true);
                    }
                  }}
                  onFocus={() => {
                    if (isInFrame && hasMouse) {
                      handleScale(item.id, isInFrame);
                    }
                  }}
                  onBlur={() => {
                    handleScale(null, true);
                  }}
                >
                  <Link href={item.url}>
                    <LinkWrapper
                      tabIndex={isInFrame ? 0 : -1}
                      sx={{
                        pointerEvents: isInFrame ? 'auto' : 'none',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: { xs: 1.5, md: 3, lg: 3.75 },
                      }}
                    >
                      <Box
                        sx={{
                          position: 'relative',
                        }}
                      >
                        <Image
                          src={item?.img?.src + imgQuery}
                          width={width}
                          height={isMobile ? '127px' : '250px'}
                          alt={item?.img?.alt}
                          loading='lazy'
                        />
                        {/* glow */}
                        <Box
                          sx={{
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            zIndex: -1,
                            borderRadius: '20px',
                            opacity: isActive ? 1 : 0,
                            transform: 'translate(-50%, -50%) translateZ(0)',
                            transition:
                              'opacity 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                            overflow: 'hidden',
                            boxShadow: isActive
                              ? '0 0 25px rgba(208, 70, 246, 0.5)'
                              : '0 0 25px rgba(208, 70, 246, 0)',
                          }}
                        />
                      </Box>
                      <Description>{item.description}</Description>
                      <Btn href={item.url} variant='tertiary' tabIndex={-1}>
                        Read the story
                      </Btn>
                    </LinkWrapper>
                  </Link>
                </Box>
              </CarouselItem>
            );
          })}
        </Slider>
      </div>

      <Canvas
        ref={canvas}
        sx={{
          width: { xs: '300%', sm: '200%', md: '100%' },
          height: '290px',
          transform: 'rotateX(180deg)',
          marginBottom: '-8px',
          mt: { xs: '20px', sm: '100px', md: '-20px' },
        }}
      />
    </Section>
  );
};

const Carousel = () => {
  const carouselCanvas = React.useRef<HTMLCanvasElement>(null);
  const { guilloche: dune, isGuillocheLoaded: isDuneLoaded } = useLazyGuilloche(
    {
      pattern: 'dune',
      canvasRef: carouselCanvas,
    },
  );
  useWorkerGuilloche({
    canvasRef: carouselCanvas,
    svgPaths: dune?.paths,
    svgWidth: dune?.width,
    svgHeight: dune?.height,
    intensity: 1,
    speed: 0.2,
    horizontalWave: true,
    verticalWave: true,
    zAxisWave: true,
    id: 'carousel-canvas',
    gradient:
      'https://images.ctfassets.net/ss5kfr270og3/63XOWq9iERnX4HB54LHXcn/11c79641a67882905bbfd6ad26c6fde6/AdobeStock_566188450__6_.png?fm=webp&q=1',
    y: { xs: -95, sm: -60, md: -82, xxl: -105 },
    scale: { xs: 1.5, sm: 1, md: 1.5, xxl: 1.75 },
    x: { xs: -15, sm: -15, md: 30, lg: -5 },
    disableAnimation: !isDuneLoaded,
  });
  return <CarouselInner canvas={carouselCanvas} />;
};

export default Carousel;
