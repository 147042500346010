import React from 'react';
import Box from 'threads5/Box';
import SxProps from 'threads5/themes/sx-props';
import Theme from 'threads5/themes/theme';

interface Props {
  sx?: SxProps<Theme>;
  containerSx?: SxProps<Theme>;
  glowSx?: SxProps<Theme>;
  isLazy?: boolean;
}

const GlowingCard: React.FC<Props> = React.forwardRef(
  ({ sx, containerSx, glowSx, children, isLazy }, ref) => {
    return (
      <Box
        component='section'
        sx={{
          height: '100vh',
          ...containerSx,
        }}
        ref={ref}
      >
        <Box
          sx={{
            display: 'flex',
            position: 'absolute',
            top: 0,
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
            zIndex: 100,
            overflow: 'hidden',
            borderRadius: '40px',
            boxShadow: '0px 0px 70px 0px rgba(139, 139, 249, 0.70)',
            ...sx,
          }}
        >
          {children}
        </Box>
      </Box>
    );
  },
);

export default GlowingCard;
