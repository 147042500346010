import React from 'react';
import styled from 'threads5/styles/styled';
import { useToggleContext } from './Toggle';
import { Image } from 'src/components-v2/Media';
import { animated, useScroll } from '@react-spring/web';

export const desktopAssets = {
  openEyes:
    'https://images.ctfassets.net/ss5kfr270og3/2JblDwCyPDIbjPx20xxK5I/c2e338a2a7bc1010adc034d8eb3f8999/besk-desktop-open.png?fm=webp',
  closedEyes:
    'https://images.ctfassets.net/ss5kfr270og3/2oJaDLy1lkPa0151ROC2EX/112bd81002fbba76fae1435de2100d83/ben-desktop-closed.png?fm=webp',
  gradient:
    'https://images.ctfassets.net/ss5kfr270og3/3BWkpdMIGOnAtu1ev1erww/bfc7fe3d2fcdc364ace3dc3d4c062f39/Tail_Holo_Strip__1_.png?fm=webp&q=30',
};

export const mobileAssets = {
  openEyes:
    'https://images.ctfassets.net/ss5kfr270og3/6WnGvAd28pPDMs6ctZoCTd/35861c4eb7a40f5b414388fbc401d2fe/ben-mobile-new-4.png?fm=webp&q=30',
  closedEyes:
    'https://images.ctfassets.net/ss5kfr270og3/42sVd0AWskymE0etREpGBs/7ece97706b583e655e69b4de64c4da7f/ben-mobile-new-4-wink.png?fm=webp&q=30',
};

export const desktopPreload = [
  {
    type: 'image/webp',
    media: '(min-width: 640px)',
    imagesrcset: `${desktopAssets.openEyes}&q=50&w=944 1x, ${desktopAssets.openEyes}&q=30 2x`,
  },
  {
    type: 'image/webp',
    media: '(min-width: 1439px)',
    imagesrcset: `${desktopAssets.gradient}&h=736 1x, ${desktopAssets.gradient}&h=1472 2x`,
  },
  {
    type: 'image/webp',
    media: '(min-width: 640px)',
    imagesrcset: `${desktopAssets.closedEyes}&q=50&w=944 1x, ${desktopAssets.closedEyes}&q=30 2x`,
  },
];

export const mobilePreload = [
  {
    type: 'image/webp',
    media: '(min-width: 440px) and (max-width: 639px)',
    imagesrcset: `${mobileAssets.openEyes}&w=458 1x, ${mobileAssets.openEyes}&w=916 2x`,
  },
  {
    type: 'image/webp',
    media: '(max-width: 439px)',
    imagesrcset: `${mobileAssets.openEyes}&w=426 1x, ${mobileAssets.openEyes}&w=852 2x`,
  },
  {
    type: 'image/webp',
    media: '(min-width: 440px) and (max-width: 639px)',
    imagesrcset: `${mobileAssets.closedEyes}&w=458 1x, ${mobileAssets.closedEyes}&w=916 2x`,
  },
  {
    type: 'image/webp',
    media: '(max-width: 439px)',
    imagesrcset: `${mobileAssets.closedEyes}&w=426 1x, ${mobileAssets.closedEyes}&w=852 2x`,
  },
];

export const assets = {
  desktop: desktopAssets,
  mobile: mobileAssets,
};

const BenImg = styled(Image)<{ haveAllCriticalAssetsLoaded?: boolean }>(
  ({ theme, haveAllCriticalAssetsLoaded }) => {
    return {
      opacity: haveAllCriticalAssetsLoaded ? 1 : 0,
      transition: 'opacity 0.3s ease-in-out',
      position: 'absolute',
      [theme.breakpoints.up('xs')]: {
        display: 'none',
        top: '105px',
        right: '-38px',
        width: '426px',
        height: '320px',
      },
      [theme.breakpoints.up(431)]: {
        right: '50%',
        transform: 'translateX(50%)',
      },
      [theme.breakpoints.up('sm')]: {
        display: 'block',
        top: 'unset',
        bottom: 0,
        right: '-400px',
        width: '844px',
        height: '650px',
        transform: 'none',
      },
      [theme.breakpoints.up('md')]: {
        bottom: 0,
        right: '-390px',
        width: '844px',
        height: '650px',
      },
      [theme.breakpoints.up('lg')]: {
        bottom: 0,
        right: '-182px',
        width: '944px',
        height: '736px',
      },
      [theme.breakpoints.up('xl')]: {
        bottom: 0,
        right: 0,
        width: '944px',
        height: '736px',
      },
      maxWidth: 'unset',
      zIndex: 1,
    };
  },
);

const GradientImg = styled(Image)<{ haveAllCriticalAssetsLoaded?: boolean }>(
  ({ theme, haveAllCriticalAssetsLoaded }) => {
    return {
      opacity: haveAllCriticalAssetsLoaded ? 1 : 0,
      transition: 'opacity 0.3s ease-in-out',
      position: 'absolute',
      maxWidth: 'unset',
      zIndex: 1,
      display: 'none',
      [theme.breakpoints.up('lg')]: {
        bottom: 0,
        width: '944px',
        height: '736px',
      },
      [theme.breakpoints.up('xl')]: {
        display: 'block',
        right: '-943px',
      },
    };
  },
);

const BenMobileContainer = styled('div')(({ theme }) => {
  return {
    position: 'absolute',
    marginTop: '90px',
    left: '-6px',
    top: '0',
    width: '426px',
    [theme.breakpoints.up(412)]: {
      left: '9px',
    },
    [theme.breakpoints.up(440)]: {
      marginTop: '100px',
      width: '458px',
      left: '12px',
    },
    [theme.breakpoints.up(460)]: {
      left: '50%',
      transform: 'translateX(-50%)',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  };
});

const MobileBen = styled(Image)(({ theme }) => {
  return {
    width: '426px',
    [theme.breakpoints.up(440)]: {
      width: '458px',
    },
  };
});

const Ben: React.FC = () => {
  const { isWinking } = useToggleContext();
  const { scrollY } = useScroll({ config: { duration: 0 } });
  const yMax = 75;

  return (
    <>
      <BenMobileContainer>
        <animated.div
          style={{
            position: 'absolute',
            transformOrigin: 'bottom center',
            opacity: scrollY.to([0, yMax], [1, 0]).to((y) => {
              return y <= 0 ? 0 : y;
            }),
            scale: scrollY.to([0, yMax], [1, 0.9]).to((y) => {
              return y >= 1 ? 1.00000001 : y <= 0.9 ? 0.9 : y;
            }),
          }}
        >
          <MobileBen
            isLazy={false}
            src={`${assets.mobile.openEyes}&w=852`}
            key={'mobile_full_eyes'}
            alt='benjamin franklin'
            width='426'
            height='320'
            sources={[
              {
                media: '(min-width: 0px) and (max-width: 439px)',
                srcSet: `${assets.mobile.openEyes}&w=426, ${assets.mobile.openEyes}&w=852 2x`,
              },
              {
                media: '(min-width: 440px) and (max-width: 639px)',
                srcSet: `${assets.mobile.openEyes}&w=458, ${assets.mobile.openEyes}&w=916 2x`,
              },
              {
                media: '(min-width:640px)',
                srcSet:
                  'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=',
              },
            ]}
          />
        </animated.div>
        <animated.div
          style={{
            position: 'absolute',
            transformOrigin: 'bottom center',
            opacity: scrollY.to([0, yMax], [0, 1]).to((y) => {
              return y >= 1 ? 1 : y;
            }),
            scale: scrollY.to([0, yMax], [1, 0.9]).to((y) => {
              return y >= 1 ? 1.00000001 : y <= 0.9 ? 0.9 : y;
            }),
          }}
        >
          <MobileBen
            isLazy={false}
            src={`${assets.mobile.closedEyes}&w=852`}
            key={'mobile_wink'}
            alt='benjamin franklin wink'
            width='426'
            height='320'
            sources={[
              {
                media: '(min-width: 0px) and (max-width: 439px)',
                srcSet: `${assets.mobile.closedEyes}&w=426, ${assets.mobile.closedEyes}&w=852 2x`,
              },
              {
                media: '(min-width: 440px) and (max-width: 639px)',
                srcSet: `${assets.mobile.closedEyes}&w=458, ${assets.mobile.closedEyes}&w=916 2x`,
              },
              {
                media: '(min-width:640px)',
                srcSet:
                  'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=',
              },
            ]}
          />
        </animated.div>
      </BenMobileContainer>
      <BenImg
        isLazy={false}
        src={`${assets.desktop[isWinking ? 'closedEyes' : 'openEyes']}&q=30`}
        key={isWinking ? 'wink' : 'full_eyes'}
        alt='benjamin franklin'
        width='944'
        height='736'
        haveAllCriticalAssetsLoaded={true}
        sources={[
          {
            media: '(max-width:639px)',
            srcSet:
              'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=',
          },
          {
            media: '(min-width: 640px)',
            srcSet: `${
              assets.desktop[isWinking ? 'closedEyes' : 'openEyes']
            }&q=50&w=944, ${
              assets.desktop[isWinking ? 'closedEyes' : 'openEyes']
            }&q=30 2x`,
          },
        ]}
      />
      <GradientImg
        src={assets.desktop.gradient + '&h=1472'}
        alt='rainbow gradient'
        width='944'
        height='736'
        haveAllCriticalAssetsLoaded={true}
        sources={[
          {
            media: '(max-width: 639px)',
            srcSet:
              'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=',
          },
          {
            media: '(min-width: 1439px)',
            srcSet: `${assets.desktop.gradient + '&h=736'}, ${
              assets.desktop.gradient
            }&h=1472 2x`,
          },
        ]}
      />
    </>
  );
};

export default Ben;
