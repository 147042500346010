import React from 'react';

import MainTemplate from 'src/templates/MainTemplate/MainTemplate';
import { Locales } from 'src/lib/constants';
import Contact from 'src/components-v3/Homepage/Contact/Contact';
import Hero from 'src/components-v3/Homepage/Hero/Hero';
import APISection from 'src/components-v3/Homepage/APISection/APISection';
import SolutionCards from 'src/components-v3/Homepage/Cards/SolutionCards';
import NetworkSection from 'src/components-v3/Homepage/Network/Network';
import { ToggleProvider } from 'src/components-v3/Homepage/Hero/Toggle';
import {
  mobilePreload,
  desktopPreload,
} from 'src/components-v3/Homepage/Hero/Ben';
import { CriticalRenderProvider } from 'src/hooks/useCriticalRenderContext';
import Portal from 'src/components-v3/Homepage/Portal/Portal';
import Carousel from 'src/components-v3/Homepage/Carousel/Carousel';
import Spacer from 'src/components-v2/Layout/Spacer/Spacer';
import Footer from 'src/components-v3/Footer';

const metaData = {
  'meta-title': 'Plaid: Enabling all companies to build fintech solutions',
  'meta-description':
    'Plaid helps all companies build fintech solutions by making it easy, safe and reliable for people to connect their financial data to apps and services.',
  hasForeignContentNotification: true,
  hasFooter: true,
  hasNavigation: true,
  noIndex: false,
  preloadImages: [...desktopPreload, ...mobilePreload],
};

const orgData = {
  '@context': 'https://schema.org',
  '@type': 'organization',
  '@id': 'https://plaid.com/#organization',
  name: 'Plaid',
  description:
    "Plaid enables you to tap into the industry's largest network of banks, fintechs, and consumers. Beyond connecting financial accounts, Plaid helps businesses onboard new customers, reduce fraud, and increase the lifetime value of customers across the user journey.",
  url: 'https://plaid.com/',
  logo: {
    '@type': 'ImageObject',
    url: 'https://security.plaid.com/api/share/f3892b6a-4afc-4cc0-ab7c-9a5363e37354/logo.png',
  },
  address: {
    '@type': 'PostalAddress',
    streetAddress: '1098 Harrison St.',
    addressLocality: 'San Francisco',
    addressRegion: 'CA',
    postalCode: '94103',
    addressCountry: 'US',
  },
  sameAs: [
    'https://www.linkedin.com/company/plaid-',
    'https://en.wikipedia.org/wiki/Plaid_Inc.',
    'https://x.com/Plaid',
    'https://x.com/PlaidDev',
    'https://www.youtube.com/c/PlaidInc',
    'https://www.instagram.com/plaid/',
  ],
};

const Guilloche = (props) => {
  return (
    <MainTemplate
      {...metaData}
      structuredData={orgData}
      locale={Locales.EN_US}
      cmsTheme='web 3.0'
      sx={{
        '&#main-content': {
          overflowX: 'unset',
          overflowY: 'unset',
          background:
            'linear-gradient(180deg, #F0F2FD 0%, #FAF7FF 35.5%, #FAF7FF 47%, #FAF7FF 68.5%, #EAEDFC 99.5%)',
        },
      }}
      hasFooter={false}
    >
      <CriticalRenderProvider value={{ assetQuerySelector: '.critical-asset' }}>
        <ToggleProvider>
          <Hero {...props} />
        </ToggleProvider>
      </CriticalRenderProvider>
      <SolutionCards {...props} />
      <NetworkSection {...props} />
      <APISection {...props} />
      <Carousel {...props} />
      <ToggleProvider>
        <Contact {...props}>
          <>
            <Spacer height={80} />
            <Portal />
          </>
        </Contact>
      </ToggleProvider>
      <Footer roundedTop overlap />
    </MainTemplate>
  );
};

export default Guilloche;
