import * as React from 'react';
import styled from 'threads5/styles/styled';
import PlaidTheme from 'threads5/themes/theme';
import { useCriticalRenderContext } from 'src/hooks/useCriticalRenderContext';
import SxProps from 'threads5/themes/sx-props';

const StyledSubheading = styled('p')<{
  haveAllCriticalAssetsLoaded: boolean;
}>(({ theme, haveAllCriticalAssetsLoaded }) => {
  return {
    color: theme.palette['base/white/white'],
    fontFamily: theme.typography.plaidSans,
    fontSize: '2.4rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '140%',
    margin: 0,
    opacity: haveAllCriticalAssetsLoaded ? 1 : 0,
    transition: 'opacity 0.3s ease-in-out',
    width: '585px',
    zIndex: 2,
    [theme.breakpoints.down('xl')]: {
      fontSize: '2rem',
      width: '520px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.6rem',
      width: '340px',
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '1.6rem',
      width: '100%',
      textAlign: 'center',
    },
  };
});

const Subheading = React.forwardRef<
  HTMLHeadingElement,
  React.ComponentPropsWithRef<'p'> & { sx?: SxProps<PlaidTheme> }
>(({ sx, ...props }, ref) => {
  const { isPlaidSansLoaded } = useCriticalRenderContext();

  return (
    <StyledSubheading
      ref={ref}
      haveAllCriticalAssetsLoaded={isPlaidSansLoaded}
      sx={sx}
      {...props}
    >
      Connect to real-time insights on the Plaid Network to create fast, safe,
      and smart financial experiences.
    </StyledSubheading>
  );
});
Subheading.displayName = 'Subheading';

export default Subheading;
