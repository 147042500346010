import React, { useEffect, useMemo } from 'react';
import { default as ReactMarquee } from 'react-fast-marquee';
import styled from 'threads5/styles/styled';
import debounce from 'lodash/debounce';
import SxProps from 'threads5/themes/sx-props';
import Theme from 'threads5/themes/theme';
import { useReducedMotion } from '@react-spring/web';

import { Box } from 'src/components-v2/Layout';
import { Image } from 'src/components-v2/Media';
import { useCriticalRenderContext } from 'src/hooks/useCriticalRenderContext';
import { useTheme } from 'threads5/styles/useTheme';
import useIsPotato from 'src/hooks/useIsPotato';

const images = [
  {
    alt: 'Rockey Money',
    src: 'https://images.ctfassets.net/ss5kfr270og3/7iG2W4PzwH1e0zuYCQXM6X/7394e86c96bddc587f129b5df89456e8/Rocket_Money_new.png',
    width: 109,
    height: 30,
    heights: {
      xs: 28,
      sm: 28,
      md: 24,
    },
  },
  {
    alt: 'Venmo',
    src: 'https://images.ctfassets.net/ss5kfr270og3/2RbvSvGfNI0j53o7ElVEn/027f9e59839bfb87d1aed7a50500628a/homepage_marquee_Venmo.png',
    width: 103,
    height: 30,
    heights: {
      xs: 28,
      sm: 28,
      md: 24,
    },
  },
  {
    alt: 'Varo',
    src: 'https://images.ctfassets.net/ss5kfr270og3/6yakrUnzfvBi7oeNO5JWTN/c8501a2a4fd536bedbab217a6da22a76/homepage_marquee_Varo.png',
    width: 63,
    height: 30,
    heights: {
      xs: 28,
      sm: 28,
      md: 24,
    },
  },
  {
    alt: 'LendingTree',
    src: 'https://images.ctfassets.net/ss5kfr270og3/4Vs4qFg4Am35anpI9Icv1W/e89d62dc58ee751d98fbf14836f31590/homepage_marquee_lending-tree.png',
    width: 109,
    height: 40,
    heights: {
      xs: 38,
      sm: 38,
      md: 34,
    },
  },
  {
    alt: 'SoFi',
    src: 'https://images.ctfassets.net/ss5kfr270og3/7cDJaoHDx04rbVumaAOwHn/0161eafae2a12308586da645b55f9e45/homepage_marquee_SoFi.png',
    width: 101,
    height: 30,
    heights: {
      xs: 28,
      sm: 28,
      md: 24,
    },
  },
  {
    alt: 'Monzo',
    src: 'https://images.ctfassets.net/ss5kfr270og3/47kT6yvxn5fE5I3Ne7fVru/f8038ab4c155db2f834cea8051cbed5e/homepage_marquee_Monzo.png',
    width: 136,
    height: 30,
    heights: {
      xs: 28,
      sm: 28,
      md: 24,
    },
  },
  {
    alt: 'Affirm',
    src: 'https://images.ctfassets.net/ss5kfr270og3/3LbQPiBkKPsP2LhceW5AH0/ba7816bcfdb60d8efe6fed12d7df6354/homepage_marquee_Affirm.png',
    width: 76,
    height: 30,
    heights: {
      xs: 28,
      sm: 28,
      md: 24,
    },
  },
  {
    alt: 'Robinhood',
    src: 'https://images.ctfassets.net/ss5kfr270og3/vebvyCNTGsbv06OpATmzL/577406f2c1798902b2264b8d3c95fae4/homepage_marquee_Robinhood.png',
    width: 143,
    height: 30,
    heights: {
      xs: 28,
      sm: 28,
      md: 24,
    },
  },
  {
    alt: 'H&R Block',
    src: 'https://images.ctfassets.net/ss5kfr270og3/675O5ORi5iOiRCz3GAmgc0/2bff4f2ee8aca4212e087ed9ad67307e/homepage_marquee_H_R_Block.png',
    width: 175,
    height: 30,
    heights: {
      xs: 28,
      sm: 28,
      md: 24,
    },
  },
  {
    alt: 'Cash App',
    src: 'https://images.ctfassets.net/ss5kfr270og3/4eckIph7qZHv0tbr8xtAzq/386100da90f85e11c48c437db61d1b30/homepage_marquee_Cashapp.png',
    width: 137,
    height: 30,
    heights: {
      xs: 28,
      sm: 28,
      md: 24,
    },
  },
  {
    alt: 'Shopify',
    src: 'https://images.ctfassets.net/ss5kfr270og3/3QT3bxelxXbbifcVMJnhk7/0342461cc51e2ac38ba420070f377a21/homepage_marquee_Shopify.png',
    width: 107,
    height: 30,
    heights: {
      xs: 28,
      sm: 28,
      md: 24,
    },
  },
  {
    alt: 'Acorns',
    src: 'https://images.ctfassets.net/ss5kfr270og3/1u2eMD81kXt4wmcIZB9Mfo/fbc04ad2f19e95309e1e3842881ffa76/homepage_marquee_Acorns.png',
    width: 120,
    height: 30,
    heights: {
      xs: 28,
      sm: 28,
      md: 24,
    },
  },
  {
    alt: 'Citi',
    src: 'https://images.ctfassets.net/ss5kfr270og3/45XLGQEjODly3gLQFrqZMp/9bea8a43f98cc0fb86fea4f9a90068cf/homepage_marquee_Citi.png',
    width: 45,
    height: 30,
    heights: {
      xs: 28,
      sm: 28,
      md: 24,
    },
  },
  {
    alt: 'Chime',
    src: 'https://images.ctfassets.net/ss5kfr270og3/7JhSt4moRPKRhn9Rw2zmVi/577303d0ee4003162da269708fcbe4b8/homepage_marquee_Chime.png',
    width: 144,
    height: 30,
    heights: {
      xs: 28,
      sm: 28,
      md: 24,
    },
  },
  {
    alt: 'Green Dot Bank',
    src: 'https://images.ctfassets.net/ss5kfr270og3/7kaF2YSu9saJk0IBcSNwsi/7c9e268dbdd3e61eb542f212e3e7a54d/homepage_marquee_greendot_bank.png',
    width: 100,
    height: 30,
    heights: {
      xs: 28,
      sm: 28,
      md: 24,
    },
  },
];

// if adjusting height, also adjust responsive image srcset sizes
const ImageBox = styled('li')(({ theme }) => {
  return {
    listStyle: 'none',
    display: 'flex',
    alignItems: 'center',
  };
});

const StyledMarqueeContainer = styled('div')(({ theme }) => {
  return {
    position: 'relative',
    zIndex: 1,
    height: '90px',
    [theme.breakpoints.down('xl')]: {
      height: '71px',
    },
    [theme.breakpoints.down('md')]: {
      height: '231px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '56px',
    },
  };
});

interface MarqueeBoxProps {
  left: number | null;
  haveAllCriticalAssetsLoaded: boolean;
  isPotato?: boolean;
}

const StyledMarqueeBox = styled(Box, {
  shouldForwardProp: (prop) => {
    return !['left', 'haveAllCriticalAssetsLoaded', 'isPotato'].includes(
      prop as string,
    );
  },
})<MarqueeBoxProps>(
  ({ theme, left, haveAllCriticalAssetsLoaded, isPotato }) => {
    const potatoHeightOffset = isPotato ? -40 : 0;
    const potatoHeightMdOffset = isPotato ? -20 : 0;
    return {
      opacity: haveAllCriticalAssetsLoaded && left !== null ? 1 : 0,
      position: 'absolute',
      transition: 'opacity 0.5s ease-in-out',
      width: '100vw',
      zIndex: 0,
      [theme.breakpoints.up('xs')]: {
        bottom: `${(isPotato ? 40 : -6) + potatoHeightOffset}px`,
        left: '-28px',
        '@media (prefers-reduced-motion: reduce)': {
          left: 0,
        },
      },
      [theme.breakpoints.up('sm')]: {
        bottom: `${54 + potatoHeightOffset}px`,
        left: `${-Math.abs(left + 380)}px`,
      },
      [theme.breakpoints.up('md')]: {
        bottom: `${-27 + potatoHeightMdOffset}px`,
        left: `${-Math.abs(left + 390)}px`,
      },
      [theme.breakpoints.up('lg')]: {
        bottom: `${-5 + potatoHeightMdOffset}px`,
        left: `${-Math.abs(left + 689)}px`,
      },
      [theme.breakpoints.up('xl')]: {
        bottom: `${-24 + potatoHeightMdOffset}px`,
        left: `${-Math.abs(left + 902)}px`,
      },
      [theme.breakpoints.up(1727)]: {
        left: `${-Math.abs(left + 905)}px`,
      },
      [theme.breakpoints.up(1919)]: {
        left: `${-Math.abs(left + 1005)}px`,
      },
      [theme.breakpoints.up(2560)]: {
        left: `${-Math.abs(left + 1319)}px`,
      },
    };
  },
);

const Ul = styled('ul')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  margin: 0,
});

const StyledImage = styled(Image)(({ theme }) => {
  return {
    display: 'inline-block',
    width: 'auto',
  };
});

const HeroMarquee = ({ sx }: { sx?: SxProps<Theme> }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [left, setLeft] = React.useState<number | null>(null);
  const { assetsLoaded } = useCriticalRenderContext();
  const theme = useTheme();
  const prefersReducedMotion = useReducedMotion();
  const isPotato = useIsPotato();

  const updateLeftPosition = useMemo(() => {
    return debounce(() => {
      if (ref.current) {
        const newLeft = ref.current.getBoundingClientRect().left;
        setLeft(newLeft);
      }
    }, 100);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', updateLeftPosition);
    return () => {
      window.removeEventListener('resize', updateLeftPosition);
      updateLeftPosition.cancel();
    };
  }, [updateLeftPosition]);

  useEffect(() => {
    if (assetsLoaded) {
      updateLeftPosition();
    }
  }, [assetsLoaded, updateLeftPosition]);

  return (
    <StyledMarqueeContainer ref={ref} sx={sx}>
      <StyledMarqueeBox
        left={left}
        haveAllCriticalAssetsLoaded={assetsLoaded}
        isPotato={isPotato}
      >
        <ReactMarquee
          pauseOnHover={false}
          pauseOnClick={false}
          gradientWidth={0}
          key='hero-logo-marquee'
          speed={prefersReducedMotion ? 0 : 50}
        >
          <Ul
            sx={{
              gap: {
                xs: '3.8rem',
                sm: '3.8rem',
                md: '8.8rem',
              },
              ':last-child': {
                marginRight: { xs: '20px', sm: '40px', md: '88px' },
              },
            }}
          >
            {images.map((img) => {
              return (
                <ImageBox key={img.alt}>
                  <StyledImage
                    src={img.src + '?fm=webp&q=1&h=' + img.height * 2}
                    alt={img.alt}
                    width={img.width}
                    height={img.height}
                    sx={{
                      height: img.heights,
                    }}
                    sources={[
                      {
                        media: `(min-width: 0px) and (max-width: ${
                          theme.breakpoints.values['sm'] - 1
                        }px)`,
                        srcSet:
                          img.src + `?fm=webp&q=1&h=${img.heights['xs'] * 2}`,
                      },
                      {
                        media: `(min-width: ${
                          theme.breakpoints.values['sm']
                        }px) and (max-width: ${
                          theme.breakpoints.values['md'] - 1
                        }px)`,
                        srcSet:
                          img.src + `?fm=webp&q=1&h=${img.heights['sm'] * 2}`,
                      },
                      {
                        media: `(min-width: ${
                          theme.breakpoints.values['md']
                        }px) and (max-width: ${
                          theme.breakpoints.values['lg'] - 1
                        }px)`,
                        srcSet:
                          img.src + `?fm=webp&q=1&h=${img.heights['md'] * 2}`,
                      },
                    ]}
                  />
                </ImageBox>
              );
            })}
          </Ul>
        </ReactMarquee>
      </StyledMarqueeBox>
    </StyledMarqueeContainer>
  );
};

export default HeroMarquee;
