import styled from 'threads5/styles/styled';
import React from 'react';
import Box from 'threads5/Box';
import Stack from 'threads5/Stack';
import SxProps from 'threads5/themes/sx-props';
import Theme from 'threads5/themes/theme';

interface Props {
  sx?: SxProps<Theme>;
}

const Line = styled(Box)(({ theme }) => {
  return {
    color: 'var(--black500, #C5C5C5)',
    textAlign: 'left',
    fontFeatureSettings: "'liga' off, 'clig' off",
    fontFamily: 'Inconsolata',
    fontSize: '2.69rem',
    fontStyle: ' normal',
    fontWeight: ' 400',
    letterSpacing: ' -0.061px',
    margin: 0,
    textOverflow: 'ellipsis',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: ' nowrap',
    width: '100%',
    [theme.breakpoints.down(1580)]: {
      fontSize: '2.69rem',
    },
    [theme.breakpoints.down('xl')]: {
      fontSize: '2.2rem',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.9rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.2rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
    },
  };
});

const BlueHighlight = styled('span')(({ theme }) => {
  return {
    color: 'var(--blue700, #37B0F4)',
  };
});

const YellowHighlight = styled('span')(({ theme }) => {
  return {
    color: 'var(--yellow800, #F2D211)',
  };
});

const CodeBlock: React.FC<Props> = ({ sx }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        background: 'var(--test-blue-1000, #0C1116)',
        borderRadius: {
          xs: '16px 16px 0px 0px',
          sm: '27.088px 27.088px 0px 0px',
        },
        boxShadow: '0px 4px 150px 0px rgba(86, 221, 247, 0.80)',
        ...sx,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          paddingX: {
            xs: '1.5rem',
            sm: '2.5rem',
            md: '3.5rem',
            lg: '4.5rem',
          },
          paddingY: {
            xs: '1.2rem',
            sm: '2.5rem',
            md: '3rem',
            lg: '2.5rem',
          },
          borderBottom: '1.826px solid var(--test-blue-700, #235F89)',
          alignItems: 'start',
        }}
      >
        <Line component='h5'>/auth/get</Line>
      </Box>
      <Box
        sx={{
          display: 'flex',
          height: '100%',
        }}
      >
        <Stack
          className='code-block-left hide-for-aeo'
          sx={{
            paddingX: {
              xs: '1.5rem',
              sm: '2.5rem',
              md: '3.5rem',
              lg: '4.5rem',
            },
            paddingTop: {
              xs: '1rem',
              sm: '1.3rem',
              md: '1.6rem',
              lg: '2rem',
            },
            paddingBottom: '2rem',
            borderRight: '1.826px solid var(--test-blue-700, #235F89)',
          }}
          gap={{ xs: '1.2rem', sm: '1.2rem' }}
        >
          <Line component='span'>1</Line>
          <Line component='span'>2</Line>
          <Line component='span'>3</Line>
          <Line component='span'>4</Line>
          <Line component='span'>5</Line>
        </Stack>
        <Stack
          sx={{
            paddingX: {
              xs: '1.5rem',
              sm: '2.5rem',
              md: '3.5rem',
              lg: '4.5rem',
            },
            paddingTop: {
              xs: '1rem',
              sm: '1.3rem',
              md: '1.6rem',
              lg: '2rem',
            },
            paddingBottom: '2rem',
            alignItems: 'start',
            overflow: 'hidden',
          }}
          gap='1.2rem'
        >
          <code data-syntax='javascript'>
            <Line component='span'>
              request = <BlueHighlight>AuthGetRequest</BlueHighlight>
              (access_token=access_token)
            </Line>
            <Line component='span'>
              response = client.<BlueHighlight>auth_get</BlueHighlight>(request)
            </Line>
            <Line component='span'>
              routing_number = response['numbers']['ach'][
              <YellowHighlight>0</YellowHighlight>]['routing']
            </Line>
            <Line component='span'>
              account_number = response['numbers']['ach'][
              <YellowHighlight>0</YellowHighlight>]['account']
            </Line>
            <Line component='span'>
              balance = response['accounts'][
              <YellowHighlight>0</YellowHighlight>
              ]['balances']['available']
            </Line>
          </code>
        </Stack>
      </Box>
    </Box>
  );
};

export default CodeBlock;
