import React, { forwardRef, useState } from 'react';
import styled from 'threads5/styles/styled';
import { animated, useSpring, useReducedMotion } from '@react-spring/web';
import SxProps from 'threads5/themes/sx-props';
import Theme from 'threads5/themes/theme';

import { Column, Row, Stack } from 'src/components-v2/Layout';
import { Typography } from 'src/components-v2/DataDisplay';
import useGradientText from 'src/hooks/useGradientText';
import useDeviceSize from 'src/hooks/useDeviceSize';

const P = styled(Typography)(({ theme }) => {
  return {
    color: '#3F0849',
    fontFamily: theme.typography.sans,
    fontSize: '2.0rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '148%',
    margin: 0,
    textAlign: 'left',
  };
});

const A = styled('a')(({ theme }) => {
  return {
    textAlign: 'left',
    fontFamily: theme.typography.sans,
    fontSize: '2.0rem',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '148%',
    background:
      'radial-gradient(circle at left, #BB33D7 30.63%, #633ECB 70.1%)',
    backgroundClip: 'text',
    color: 'transparent',
  };
});

interface LinkProps {
  children: React.ReactNode;
  href: string;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  sx?: SxProps<Theme>;
}

const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ children, href, onMouseOver, onMouseOut, onFocus, onBlur, sx }, ref) => {
    return (
      <Stack
        direction='row'
        alignItems='center'
        gap={1}
        justifyContent='start'
        width={{ xs: '100%', md: 'auto' }}
      >
        <A
          href={href}
          ref={ref}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          onFocus={onFocus}
          onBlur={onBlur}
          sx={sx}
        >
          {children}
        </A>
      </Stack>
    );
  },
);

const Lock = styled(animated.img)(({ theme }) => {
  return {
    transform: 'translateY(20px)',
    opacity: 0.2,
  };
});

export const Portal = () => {
  const { elementRef, handleMouseMove, parentRef } = useGradientText<
    HTMLAnchorElement,
    HTMLDivElement
  >({
    gradientColors: '#BB33D7 30.63%, #633ECB 70.1%',
  });
  const [isActive, setIsActive] = useState(false);
  const { isLarge } = useDeviceSize();
  const prefersReducedMotion = useReducedMotion();

  const handleAnimation = (active: boolean) => {
    setIsActive(active);
  };

  const opacityStart = isLarge ? 0.2 : 1;
  const opacityEnd = 1;
  const animation = useSpring({
    transform:
      isActive && !prefersReducedMotion
        ? 'translate(0px, 18px)'
        : 'translate(0px, 22px)',
    opacity: isActive ? opacityEnd : opacityStart,
  });

  return (
    <Row
      ref={parentRef}
      onMouseMove={handleMouseMove}
      sx={{ position: 'relative', zIndex: 1 }}
    >
      <Column
        xsOffset={2}
        xs={20}
        mdOffset={1}
        md={22}
        onMouseOver={() => {
          return handleAnimation(true);
        }}
        onMouseOut={() => {
          return handleAnimation(false);
        }}
      >
        <Stack
          sx={{
            position: 'relative',
            background: '#EADDFD',
            borderRadius: '50px',
            padding: { xs: '32px', md: '32px 54px' },
            overflow: 'hidden',
          }}
          justifyContent='space-between'
          direction={{ xs: 'column', md: 'row' }}
          alignItems='center'
          gap={{ xs: 1.5, md: 4 }}
        >
          <P sx={{ fontSize: { xs: '1.6rem', md: '2.0rem' }, zIndex: 1 }}>
            When you connect to an app with Plaid, you're in control of who has
            access to your financial data.
          </P>
          <Lock
            src='https://images.ctfassets.net/ss5kfr270og3/5PCUD4M6ywcSiSsfd3tQRR/6d412ef900d7ee8d9f403217b2bdcac5/Spot-Lock-001__1_.png?q=1&fm=webp'
            width='91'
            height='114'
            alt='lock'
            loading='lazy'
            style={animation}
            sx={{
              position: 'absolute',
              bottom: '-8px',
              right: { xs: '10%', xl: '30%' },
              zIndex: 0,
            }}
          />
          <Link
            href='https://my.plaid.com'
            ref={elementRef}
            onFocus={() => {
              return handleAnimation(true);
            }}
            onBlur={() => {
              return handleAnimation(false);
            }}
            sx={{
              fontSize: { xs: '1.6rem', md: '2.0rem' },
              zIndex: 1,
              whiteSpace: { xs: 'normal', lg: 'nowrap' },
            }}
          >
            Manage your connections with Plaid Portal &#187;
          </Link>
        </Stack>
      </Column>
    </Row>
  );
};

export default Portal;
