import { useMediaQuery } from '@mui/system';
import React from 'react';
import { Box } from 'src/components-v2/Layout';
import SxProps from 'threads5/themes/sx-props';
import Theme from 'threads5/themes/theme';
import theme from 'src/components-v2/theme';

export interface MomentProps {
  delay?: number;
  sx?: SxProps<Theme>;
}

const FloatingMoment: React.FC<MomentProps> = ({
  children,
  delay = 250,
  sx,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [shouldShow, setShouldShow] = React.useState(false);
  const aboveMd = useMediaQuery(theme.breakpoints.up('md'));

  const onObserve = (entries) => {
    // allow count if visible
    entries.forEach((entry) => {
      if (entry.isIntersecting && !shouldShow) {
        setTimeout(() => {
          setShouldShow(true);
        }, delay);
      }
    });
  };

  React.useEffect(() => {
    const observer = new IntersectionObserver(onObserve, {
      rootMargin: `0px 0px 0px 0px`,
      threshold: 0.2,
    });
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [onObserve, shouldShow]);

  return (
    <Box
      ref={ref}
      sx={{
        position: 'absolute',
        transform:
          shouldShow || !aboveMd
            ? 'translateY(-25px) scale(1)'
            : 'translateY(0) scale(0.9)',
        opacity: shouldShow || !aboveMd ? 1 : 0,
        transition: aboveMd && 'transform 1.5s, opacity 1.5s',
        display: {
          xs: 'none',
          lg: 'block',
        },
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default FloatingMoment;
