import styled from 'threads5/styles/styled';
import { animated } from '@react-spring/web';

export const Slider = styled(animated.ul)(({ theme }) => {
  return {
    gap: theme.spacing(5),
    position: 'relative',
    padding: 0,
    margin: 0,
  };
});

export const CarouselItem = styled(animated.li)(() => {
  return {
    position: 'absolute',
    left: 0,
    listStyle: 'none',
    margin: 0,
    padding: 0,
    listStyleType: 'none',
    transformOrigin: 'top',
  };
});

export const Description = styled('p')(({ theme }) => {
  return {
    color: '#02294B',
    fontFamily: theme.typography.plaidSans,
    fontSize: '30px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '120%',
    letterSpacing: '-0.9px',
    margin: 0,
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.0rem',
      lineHeight: '125%',
      letterSpacing: '-0.8px',
    },
  };
});

export const Title = styled('h2')(({ theme }) => {
  return {
    fontFamily: theme.typography.plaidSans,
    fontSize: '50px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '94%',
    letterSpacing: '-2px',
    [theme.breakpoints.down('md')]: {
      fontSize: '45px',
      lineHeight: '104%',
      letterSpacing: '-1.35px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '25px',
      lineHeight: '110%',
      letterSpacing: '-1px',
    },
  };
});

export const Image = styled('img')(() => {
  return {
    borderRadius: '20px',
  };
});

export const LinkWrapper = styled('a')({});

export const Canvas = styled('canvas')({});
