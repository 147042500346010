import { useState, useEffect } from 'react';

const useHasMouse = () => {
  const [hasMouse, setHasMouse] = useState(false);

  useEffect(() => {
    const checkMouseSupport = () => {
      const mouseDetected = window.matchMedia('(pointer:fine)').matches;
      setHasMouse(mouseDetected);
    };

    checkMouseSupport();

    const mediaQuery = window.matchMedia('(pointer:fine)');
    mediaQuery.addEventListener('change', checkMouseSupport);

    return () => {
      mediaQuery.removeEventListener('change', checkMouseSupport);
    };
  }, []);

  return hasMouse;
};

export default useHasMouse;
