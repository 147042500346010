import * as React from 'react';
import { string } from 'yup';
import styled from 'threads5/styles/styled';
import Text from 'threads5/Text';
import { useSpring, animated } from '@react-spring/web';
import useIsPotato from 'src/hooks/useIsPotato';
import Link from 'src/components-v2/Inputs/Link/Link';

import {
  Column,
  Row,
  Container,
  Box,
  Spacer,
  Stack,
} from 'src/components-v2/Layout';
import { getFormData } from 'src/components/Forms/ContactForm/contactFormData';
import { usePageLocale } from 'src/contexts/LocaleContext';
import { TextInput } from 'src/components/Inputs';
import { MarketoForm, GdprFields } from 'src/components/Forms';
import { getRequiredMessage } from 'src/lib/utils';
import useGradientText from 'src/hooks/useGradientText';
import Thumbprint from '../Hero/Thumbprint';
import { Button as SubmitButton } from 'src/components-v3/Homepage/Buttons/Button';

const Title = styled('h2')(({ theme }) => {
  return {
    zIndex: 1,
    fontFamily: theme.typography.plaidSans,
    fontSize: '3.0rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '110%',
    letterSpacing: '-0.9px',
    background:
      'linear-gradient(87deg, #86EF5A 0.86%, #10D0B7 38.09%, #E9FFDB 69.81%)',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    margin: 0,
  };
});

const Heading = styled('h3')(({ theme }) => {
  return {
    color: '#02294B',
    fontFamily: theme.typography.plaidSans,
    fontSize: '2.0rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '120%',
    letterSpacing: '-0.6px',
    margin: 0,
  };
});

const Disclaimer = styled(Text)(({ theme }) => {
  return {
    color: '#24555B',
    fontFamily: theme.typography.sans,
    fontSize: '1.4rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '133%',
    opacity: 0.58,
  };
});

const Border = styled(animated.img)({});

const Card = ({ children }: { children: React.ReactNode }) => {
  const isPotato = useIsPotato();
  const [spring, api] = useSpring(() => {
    return {
      rotateZ: 0,
      config: { duration: 7500 },
    };
  });

  return (
    <Box
      sx={{
        position: 'relative',
      }}
      onMouseEnter={() => {
        if (isPotato) {
          return;
        }
        api.start({
          loop: { reverse: false },
          to: async (next) => {
            while (true) {
              await next({ rotateZ: 360 });
              api.set({ rotateZ: 0 });
            }
          },
        });
      }}
      onMouseLeave={() => {
        api.stop();
      }}
    >
      <Box
        sx={{
          padding: { xs: '2px', md: '5px' },
          borderRadius: { xs: '18px', md: '40px' },
          zIndex: 1,
          position: 'relative',
          overflow: 'hidden',
          boxShadow: '0px 0px 36px 5px rgba(243, 49, 247, 0.43)',
        }}
      >
        <Box
          sx={{
            background: 'white',
            width: '100%',
            height: '100%',
            borderRadius: { xs: '17px', md: '35px' },
            padding: { xs: '37px 32px 50px', sm: '60px 63px', md: '70px' },
          }}
        >
          {children}
        </Box>
        <Border
          className='card-border'
          role='presentation'
          alt=''
          src='https://images.ctfassets.net/ss5kfr270og3/2QX6nduCZaLnMJmP8D2VBR/ecd2946f6c9d506a727f9cd1d0544984/AdobeStock_566188450__3_.png?fm=webp&q=1'
          loading='lazy'
          sx={{
            position: 'absolute',
            top: '-25%',
            left: '-50%',
            zIndex: -1,
            maxWidth: '200%',
            height: '200%',
            transformOrigin: 'center',
          }}
          style={{
            transform: spring.rotateZ.to((r: number) => {
              return `rotate(${r}deg)`;
            }),
          }}
        />
      </Box>
    </Box>
  );
};

const Strip = styled('div')({
  height: '17px',
  background: `url(https://images.ctfassets.net/ss5kfr270og3/7q1mJPw6bmeeUruloUUcxa/d69826c7922a24d66660ccd4e732a79a/3621384b921832f85bca12c7d2a4f5da?fm=webp&q=30)`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
});

const Contact = ({ children, ...props }) => {
  const { elementRef, handleMouseMove, parentRef } = useGradientText<
    HTMLHeadingElement,
    HTMLDivElement
  >({
    gradientColors: '#86EF5A 0.86%, #10D0B7 38.09%, #FFFFFF 69.81%',
  });
  const locale = usePageLocale();
  const contactFormData = getFormData({ locale });
  return (
    <>
      <Strip />
      <Box
        className='hide-for-aeo'
        id='contactForm'
        ref={parentRef}
        component='section'
        onMouseMove={handleMouseMove}
        sx={{
          background:
            'linear-gradient(101deg, #07578C 25.49%, #0E94D8 55.78%, #42F0CD 86.17%)',
          position: 'relative',
        }}
      >
        <Spacer sx={{ height: { xs: '44px', md: '112px' } }} />
        <Container sx={{ pb: { xs: '70px', md: '112px' } }}>
          <Row>
            <Column xsOffset={2} xs={20} mdOffset={1} md={8}>
              <Stack
                sx={{
                  height: { xs: 'auto', md: '100%' },
                  justifyContent: 'center',
                }}
              >
                <Title
                  ref={elementRef}
                  sx={{
                    fontSize: {
                      xs: '3.0rem',
                      sm: '4.5rem',
                      md: '5.0rem',
                      lg: '7.0rem',
                    },
                  }}
                >
                  More customers.
                  <br />
                  Less fraud.
                  <br />
                  Higher revenue.
                </Title>
                <Spacer sx={{ height: { xs: '32px' } }} />
              </Stack>
            </Column>
            <Column xsOffset={2} xs={20} mdOffset={2} md={12}>
              <Card>
                <Heading
                  sx={{
                    fontSize: {
                      xs: '2.0rem',
                      sm: '4.0rem',
                      md: '3.0rem',
                    },
                  }}
                >
                  Let's get started
                </Heading>
                <Spacer sx={{ height: { xs: '32px' } }} />
                <MarketoForm
                  marketoKey={2007}
                  trackingId='CONTACT_FORM'
                  locale={locale}
                  render={({ control, register, errors, setValue }) => {
                    return (
                      <Box
                        sx={{
                          'input[type="text"]': {
                            borderRadius: '8px',
                            border: '1px solid #02294B',
                            mb: '8px',
                          },
                          'fieldset[class^=BaseInput-module_container]': {
                            mb: '32px',
                          },
                          '.Select_selectControl__FDaI8': {
                            borderRadius: '8px',
                            border: errors?.Country?.message
                              ? '1px solid #f44e66'
                              : '1px solid #02294B',
                          },
                          'span[class^=BaseInput-module_note]': {
                            fontSize: '1.4rem',
                          },
                          '.customSelect__menu': {
                            height: '185px !important',
                            '& > div': {
                              height: '184px !important',
                            },
                          },
                        }}
                      >
                        <Stack direction='column' gap={{ xs: 0, sm: 0 }}>
                          <Stack
                            direction={{ xs: 'column', sm: 'row' }}
                            gap={{ xs: 0, sm: 2 }}
                          >
                            <TextInput
                              label={contactFormData.firstName.label}
                              id={contactFormData.firstName.name}
                              name={contactFormData.firstName.name}
                              describedby={
                                contactFormData.firstName.describedby
                              }
                              errorMessage={
                                errors?.[contactFormData.firstName.name]
                                  ?.message
                              }
                              register={register}
                              required={getRequiredMessage(
                                contactFormData.firstName.label,
                              )}
                              autoComplete={
                                contactFormData.firstName.autoComplete
                              }
                            />
                            <TextInput
                              label={contactFormData.lastName.label}
                              id={contactFormData.lastName.name}
                              name={contactFormData.lastName.name}
                              describedby={contactFormData.lastName.describedby}
                              errorMessage={
                                errors?.[contactFormData.lastName.name]?.message
                              }
                              register={register}
                              required={getRequiredMessage(
                                contactFormData.lastName.label,
                              )}
                              autoComplete={
                                contactFormData.lastName.autoComplete
                              }
                            />
                          </Stack>
                          <Stack
                            direction={{ xs: 'column', sm: 'row' }}
                            gap={{ xs: 0, sm: 2 }}
                          >
                            <TextInput
                              label={contactFormData.email.label}
                              id={contactFormData.email.name}
                              name={contactFormData.email.name}
                              describedby={contactFormData.email.describedby}
                              errorMessage={errors?.Email?.message}
                              register={register}
                              required={getRequiredMessage(
                                contactFormData.email.label,
                              )}
                              pattern={{
                                value:
                                  string().email().tests[0].OPTIONS.params
                                    .regex,
                                message: contactFormData.email.invalidMessage,
                              }}
                              value=''
                              autoComplete={contactFormData.email.autoComplete}
                            />
                            <TextInput
                              label={contactFormData.company.label}
                              id={contactFormData.company.name}
                              name={contactFormData.company.name}
                              describedby={contactFormData.company.describedby}
                              errorMessage={
                                errors?.[contactFormData.company.name]?.message
                              }
                              register={register}
                              required={getRequiredMessage(
                                contactFormData.company.label,
                              )}
                              autoComplete={
                                contactFormData.company.autoComplete
                              }
                            />
                          </Stack>
                          <Stack
                            direction={{
                              xs: 'column',
                              sm: 'row',
                            }}
                            gap={{ xs: 0, sm: 2 }}
                            sx={{
                              fieldset: {
                                mb: { xs: '40px', sm: '16px !important' },
                              },
                              'div[class^=BaseInput-module_container]': {
                                mb: 0,
                              },
                            }}
                          >
                            <GdprFields
                              control={control}
                              errors={errors}
                              register={register}
                              setValue={setValue}
                            />
                            <TextInput
                              label={contactFormData.phone.label}
                              id={contactFormData.phone.name}
                              name={contactFormData.phone.name}
                              describedby={contactFormData.phone.describedby}
                              register={register}
                              autoComplete={contactFormData.phone.autoComplete}
                            />
                          </Stack>
                        </Stack>
                        <Disclaimer sx={{ mb: { xs: '20px', sm: '16px' } }}>
                          By submitting this form, I confirm that I have read
                          and understood{' '}
                          <Link href='/legal/#privacy-statement'>
                            <a>Plaid's Privacy Statement</a>
                          </Link>
                          .
                        </Disclaimer>
                        <Stack
                          direction='row'
                          justifyContent={{ xs: 'center', sm: 'flex-start' }}
                        >
                          <SubmitButton
                            type='submit'
                            variant='benjamin'
                            sx={{ fontSize: { xs: '1.6rem', sm: '2.0rem' } }}
                          >
                            Talk with our team
                          </SubmitButton>
                        </Stack>
                      </Box>
                    );
                  }}
                />
              </Card>
            </Column>
          </Row>
          {children}
        </Container>
        <Thumbprint key='contact' />
      </Box>
    </>
  );
};

export default Contact;
