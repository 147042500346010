import styled from 'threads5/styles/styled';
import React from 'react';
import { Box, Stack } from 'src/components-v2/Layout';
import { Image } from 'src/components-v2/Media';
import SxProps from 'threads5/themes/sx-props';
import Theme from 'threads5/themes/theme';

interface Props {
  icon: string;
  iconAlt: string;
  title: string;
  description: string;
  time: string;
  sx?: SxProps<Theme>;
}

const Description = styled('p')(({ theme }) => {
  return {
    color: '#595B5C',
    fontSize: '1.2906rem',
    margin: 0,
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
    },
  };
});

const Time = styled('p')(({ theme }) => {
  return {
    color: 'rgba(62, 62, 62, 0.50)',
    fontSize: '1.2796rem',
    margin: 0,
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
    },
  };
});

const Title = styled('p')(({ theme }) => {
  return {
    color: '#232424',
    fontSize: '2rem',
    fontWeight: 500,
    margin: 0,
    [theme.breakpoints.down('md')]: {
      fontSize: '1.6rem',
    },
  };
});

const NotificationMoment: React.FC<Props> = ({
  icon,
  iconAlt,
  title,
  description,
  time,
  sx,
}) => {
  return (
    <Stack
      direction='row'
      gap={1.5}
      sx={{
        border: '0.924px solid rgba(17, 17, 17, 0.08)',
        borderRadius: '16px',
        boxShadow:
          '0px 86.756px 24.378px 0px rgba(125, 125, 125, 0.00), 0px 55.209px 22.227px 0px rgba(125, 125, 125, 0.01), 0px 30.831px 18.642px 0px rgba(125, 125, 125, 0.05), 0px 13.623px 13.623px 0px rgba(125, 125, 125, 0.09), 0px 3.585px 7.887px 0px rgba(125, 125, 125, 0.10)',
        backgroundColor: 'white',
        minWidth: {
          xs: '260px',
          md: '320px',
        },
        p: {
          xs: '10px',
          md: '13.5px',
        },
        ...sx,
      }}
    >
      <Image
        src={icon}
        isLazy={true}
        width={70}
        height={70}
        alt={iconAlt}
        sx={{
          width: {
            xs: 56,
            md: 70,
          },
        }}
      />
      <Stack gap={0.5} sx={{ width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Description>{description}</Description>
          <Time>{time}</Time>
        </Box>
        <Title>{title}</Title>
      </Stack>
    </Stack>
  );
};

export default NotificationMoment;
