export const baseItems = [
  {
    img: {
      src: 'https://images.ctfassets.net/ss5kfr270og3/3DDGgqXO0LLkiyZhR2M5Q5/5657937f8247c10d92bd8318bae3b39b/Frame_2049518600.png',
      alt: 'Zillow',
    },
    description: 'Zillow Home Loans pre-approved loans 29% faster',
    url: '/customer-stories/zillow-home-loans/',
  },
  {
    img: {
      src: 'https://images.ctfassets.net/ss5kfr270og3/22PrwDgb1t0iLBJJK90KmY/4604d5791d384597e42a30a65fc3a644/1.png',
      alt: 'H&R Block',
    },
    description: 'H&R Block scaled their neobank to 300K+ users',
    url: '/customer-stories/hrblock/',
  },
  {
    img: {
      src: 'https://images.ctfassets.net/ss5kfr270og3/1AXNMQQ5wP82OOxq9OdSyt/8d11886698ccd2910c06a1c4fd426d72/2.png',
      alt: 'Affirm',
    },
    description: 'Affirm pioneered a new way to mitigate risk',
    url: '/customer-stories/affirm/',
  },
  {
    img: {
      src: 'https://images.ctfassets.net/ss5kfr270og3/1j94oTdWgvsLs7ifl5H8zR/b78604832190a63e0ff844b6dba7c271/5.png',
      alt: 'Robinhood',
    },
    description: 'Robinhood unlocked $100M in instant funds',
    url: '/customer-stories/robinhood/',
  },
  {
    img: {
      src: 'https://images.ctfassets.net/ss5kfr270og3/7FBpwUvbL9aVeLgbt6CqKP/968c72bc294f07218da2658ddbfe677e/4.png',
      alt: 'Chime',
    },
    description: 'Chime boosted account funding by 300%',
    url: '/customer-stories/chime/',
  },
  {
    img: {
      src: 'https://images.ctfassets.net/ss5kfr270og3/73fAdotLMPzZtQOzjrmU0F/75b4694388a6518692f9ea892cec0884/3.png',
      alt: 'Flexport',
    },
    description: 'Flexport unlocked 32% more capital for logistic partners',
    url: '/customer-stories/flexport/',
  },
  {
    img: {
      src: 'https://images.ctfassets.net/ss5kfr270og3/6oWhF53UO6PJEnpZl6EcmR/55ce69baa5e2d60d0113d1aaeaabbea5/13.png',
      alt: 'Branch',
    },
    description: 'Branch gave users access to earned wages',
    url: '/customer-stories/branch/',
  },
  {
    img: {
      src: 'https://images.ctfassets.net/ss5kfr270og3/2ijVyFAEWxK897arnFgbW0/b1edecfe287ae026104b53bee510909c/16.png',
      alt: 'Ellevest',
    },
    description: 'Ellevest improved conversion by 111%',
    url: '/customer-stories/ellevest/',
  },
  {
    img: {
      src: 'https://images.ctfassets.net/ss5kfr270og3/1Sg1t5HWlcvxXmrC5U06CI/2922227fb1578445f29d0ed6c044a5b5/14.png',
      alt: 'Prosper',
    },
    description: 'Prosper gained access to 24-months of transactions',
    url: '/customer-stories/prosper/',
  },
  {
    img: {
      src: 'https://images.ctfassets.net/ss5kfr270og3/3aaWmhkLFrb97GHUtci8Jd/798b935bfb70809e7426e116c75881a0/15.png',
      alt: 'Green Dot',
    },
    description: 'Green Dot launched new open banking features',
    url: '/customer-stories/green-dot/',
  },
  {
    img: {
      src: 'https://images.ctfassets.net/ss5kfr270og3/5FHdArXF4iFgxA3o9Vrdlt/1fe5e8569920c5ecd6a993c04fe2a777/12.png',
      alt: 'Uphold',
    },
    description: 'Uphold reduced return losses by 80%',
    url: '/customer-stories/uphold/',
  },
  {
    img: {
      src: 'https://images.ctfassets.net/ss5kfr270og3/2d8umLe4u8Gu6px3OnEQMU/660b07daf250c810794f59595b5fb244/6.png',
      alt: 'Adyen',
    },
    description: 'Adyen accelerated pay by bank adoption',
    url: '/customer-stories/adyen/',
  },
  {
    img: {
      src: 'https://images.ctfassets.net/ss5kfr270og3/5gTKCwiU1EBdb0njTVKIpn/58a3aaa7f1889477c00095ba3b0c1f35/7.png',
      alt: 'SoFi',
    },
    description: 'SoFi unlocked real-time  financial insights',
    url: '/customer-stories/sofi/',
  },
  {
    img: {
      src: 'https://images.ctfassets.net/ss5kfr270og3/VV6YXvna6MS1eHj27ycuL/8c39d71311f261fb7cd144734d201c60/10.png',
      alt: 'PadSplit',
    },
    description: 'PadSplit verified income in under 60 seconds',
    url: '/customer-stories/padsplit/',
  },
  {
    img: {
      src: 'https://images.ctfassets.net/ss5kfr270og3/3AOVyZHoiYQsr1u3dvreWH/6cd4a573692657e339e651b581ff23da/11.png',
      alt: 'MSUFCU',
    },
    description: 'MSUFCU lowered support tickets by 67%',
    url: '/customer-stories/msufcu/',
  },

  {
    img: {
      src: 'https://images.ctfassets.net/ss5kfr270og3/PDLIwC4VUfxS5jiwtkQ4R/234ed355f1e06b8d15e5f881867b0047/9.png',
      alt: 'Copilot',
    },
    description: 'Copilot created an award-winning user experience',
    url: '/customer-stories/copilot/',
  },
  {
    img: {
      src: 'https://images.ctfassets.net/ss5kfr270og3/458T8KFzLo3PG5TCD5fUmz/43a90538e538a96db69359cd201b4d08/8.png',
      alt: 'Varo',
    },
    description: 'Varo Bank delivered frictionless onboarding ',
    url: '/customer-stories/varo/',
  },
];
